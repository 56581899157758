.empty-screen {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    &__img-wrapper {
      max-width: 250px;
      height: 188px;
    }
    .button__large {
      height: 48px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      &__label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--adf-color-text-80-light);
      }
      &__sub-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: var(--adf-color-text-60-light);
      }
    }
  }
}

.item-wrapper {
  height: 150px;
  padding: 10px;
  background: var(--adf-color-white-border);
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  .name-wrapper {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    overflow: hidden;
    line-height: 25px;
  }

  .info-wrapper {
    bottom: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    min-height: 40px;
    background-color: var(--adf-color-input-border-light);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;

    .date-wrapper {
      font-size: 12px;
      color: var(--node-color);
    }
    &:hover {
      .deleteIcon-wrapper {
        color: #fff;
        background-color: #a9017c;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        font-size: 16px;
        align-items: center;
      }
    }
    .deleteIcon-wrapper {
      cursor: pointer;
      display: none;
    }
  }
}
