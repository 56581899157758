.api-collections {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;

  &__header {
    top: 0;
    width: 100%;
    background-color: var(--bg-primary);
    height: 72px;
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    .go-back {
      display: flex;
      gap: 0;
      align-items: center;
      color: var(--adf-color-text-20-light);
      font-weight: 500;
      cursor: pointer;
    }
  }
  &__title {
    color: var(--title);
    font-weight: 600;
    font-size: 24px;
  }

  &__inner {
    position: relative;
    display: flex;
    padding: 16px;
    gap: 24px;
    &__left {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &__right {
      width: 100%;
    }
  }
}
