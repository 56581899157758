.file-upload {
  background: var(--adf-color-input-background);
  border: 1px dashed var(--border-dashed);
  border-radius: 8px;
  cursor: pointer;
  display: grid;
  height: 100%;
  place-items: center;
  width: 100%;
  padding: 16px;
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    &__upload-icon {
      color: var(--adf-color-primary-dark);
      display: flex;
      font-size: 60px;
    }
    &__label {
      color: var(--adf-color-text-10-light);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      span {
        color: var(--adf-color-primary-dark);
        font-weight: 600;
      }
    }
  }
}
