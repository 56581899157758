.environment-settings {
  height: 100%t;
  &__inner {
    background: var(--adf-color-bg-100-light);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--adf-color-input-border-light);
    gap: 40px;
    &__select-environment {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--title);
    }
    &__sub-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #747e99;
    }
    &__select-environment-header {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 4px;
    }
    &__edit-icon {
      color: #6c7489;
      font-size: 20px;
      cursor: pointer;
    }
    &__inputs {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .go-to-setting-btn {
      height: 48px;
      margin-top: 16px;
    }
    .all-service-wrapper {
      display: flex;
      align-items: center;
      gap: 24px;
      .api-service-box {
        border: 1px solid var(--adf-color-input-border-light);
        border-radius: 6px;
        padding: 16px;
        width: 250px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__icon {
          color: #6c7489;
          font-size: 24px;
        }
        &__labels {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          &__label {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: var(--adf-color-text-70-light);
          }
          &__sub-label {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            text-align: center;

            color: #8c97b8;
          }
        }
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  .border {
    border-bottom: 1px solid #d7dff5;
  }
}
