:root {
  --tooltip-text-color: black;
  --tooltip-background-color: white;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
  --tooltip-border-color: black;
}

.dark-mode {
  --tooltip-text-color:  white;
  --tooltip-border-color: white;
  --tooltip-background-color: black;
}

.tooltip-wrapper {
  display: inline-flex;
  position: relative;
}

.tooltip-tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: normal;
  width: 500px;
  max-width: max-content;
  display: flex;
  align-items: center;
  border: 0.25px solid var(--tooltip-border-color); /* Added border */

  &::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
    position: absolute;
    pointer-events: none;
  }
}

.tooltip-tip.top {
  top: calc(var(--tooltip-margin) * -1);
  &::before {
    top: 100%;
    border-top-color: var(--tooltip-border-color);
    border-bottom: none; /* Ensures arrow tip doesn't have square border */
  }
}

.tooltip-tip.right {
  left: 100%;
  top: 50%;
  transform: translateX(0) translateY(-50%);
  &::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-border-color);
    border-left: none; /* Ensures arrow tip doesn't have square border */
  }
}

.tooltip-tip.bottom {
  top: 100%;
  &::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-border-color);
    border-top: none; /* Ensures arrow tip doesn't have square border */
  }
}

.tooltip-tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
  &::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-border-color);
    border-right: none; /* Ensures arrow tip doesn't have square border */
  }
}

