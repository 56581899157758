.container {
  height: 80px;
  width: 100%;
  left: 248px;
  top: 0px;
  border-radius: 0px;
  border-bottom: 1px solid var(--adf-color-white-border);
  display: flex;
  align-items: center;
  padding: 32px 24px;
}

.lable {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--adf-color-text-06-light);
}
