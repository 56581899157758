.circle-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loader {
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.loader-blue {
  border: 3px solid var(--adf-color-border);
  border-top: 3px solid var(--adf-color-bg-100-light);
}
.loader-white {
  border: 3px solid var(--adf-color-bg-100-light);
  border-top: 3px solid var(--adf-color-primary-light);
}
.loader-pink {
  border: 3px solid var(--adf-color-bg-100-light);
  border-top: 3px solid var(--adf-color-danger-dark);
}
.gif-loader {
  width: 100px;
  height: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
