@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@import "variables/scss/variable.scss";
@import "overrides";

.theme-toggle-icon {
  position: fixed;
  z-index: 1;
  right: 48px;
  top: 24px;
  font-size: 24px;
  cursor: pointer;
}

.app-container {
  background: var(--adf-color-bg-100-light);
  color: var(--text-color);
  display: flex;
  height: 100vh;

  &__inner {
    position: relative;
    flex: 1 1;
    width: calc(100% - 200px);
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active-linking {
  .rd3t-g {
    transform: scale(1, 1) !important;
  }
}

.luckysheetsheetchange {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.adComp {
  text-align: left;
}

.adf-toaster {
  text-align: left;

  .Toastify__toast-theme--light {
    background: var(--bg-primary);
    color: var(--adf-color-text-90-light);

    .Toastify__close-button--light {
      color: var(--adf-color-text-100-light);
      opacity: 0.3;
    }
  }
}

.theme-toggle-icon {
  color: var(--theme-toggle-color);
}

.rd3t-link {
  fill: none;
  stroke: #f5af45 !important;
  stroke-width: 2px !important;
}

.rd3t-node,
.rd3t-leaf-node {
  cursor: pointer;
  fill: var(--node-bg-color) !important;
  stroke: var(--node-color) !important;
  stroke-width: 1 !important;
  font-size: 10px;
}

.rd3t-svg {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}

.rd3t-node {
  &:hover {
    .addIcon {
      display: block;
    }
  }
}

.node-wrapper-active {
  border: 2px solid #f5af45 !important;
}

.node-wrapper {
  background: var(--node-bg-color);
  border: 2px solid var(--adf-color-input-border-light);
  border-radius: 8px;
  width: 150px;
  max-height: 40px;
  min-height: 40px;
  display: grid;
  place-items: center;

  &:hover {
    .addIcon {
      visibility: visible;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    // display: flex;
    align-items: center;
    color: var(--node-color);
  }
}
.node-disabled {
  border: 2px solid var(--adf-disable-border);
  background: var(--adf-disable-bg);
  h3 {
    color: var(--adf-disable-text-color);
  }
}

.addIcon {
  visibility: hidden;
}

.icon-action {
  &__circle {
    stroke: var(--node-stroke) !important;
    fill: var(--node-bg-color) !important;
    stroke-width: 2 !important;
  }

  &__text {
    stroke: var(--node-stroke) !important;
  }
}

.api-designer-navigator {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-bar);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar);
}
