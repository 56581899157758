.adf-dropdown {
  width: 100%;
  input {
    color: var(--input-color) !important;
  }
  &--disabled {
    opacity: 0.5;
  }
}
.option {
  display: flex;
  gap: calc(var(--margin) * 0.25);
  .option-name {
    color: var(--adf-color-text-100-dark);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .option-subname {
    color: var(--adf-color-text-100-dark);
  }
}
