.documentation-wrapper {
  padding: 24px;
  width: 100%;
}

.copy-icon {
  cursor: pointer;
}

.copy-icon:active {
  color: var(--icon-color);
}
