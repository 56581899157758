.fetch-url {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  .input__field {
    width: calc(100% - 100px);
  }
  &__fetch-button {
    width: 77px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #d7dff5;
    border-radius: 6px;
    position: absolute;
    right: 16px;
    top: 34px;
  }
}
