.api-settings {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  padding-top: 16px;
  text-align: initial;
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: var(--adf-color-text-90-light);
  }

  &__sub-info {
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--adf-color-text-90-light);
      // text-align: initial;
    }
    &__sub-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      // text-align: initial;
      color: #747e99;
    }
  }
  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: calc(100vh - 220px);
    padding-right: 16px;
    overflow-y: scroll;
    @media only screen and (max-width: 1100px) {
      height: calc(100vh - 370px);
    }
  }

  &__api-settings-buttons {
    height: 48px;
    @media only screen and (max-width: 1100px) {
      height: 110px;
    }
    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 16px;
      flex-wrap: wrap;
      @media only screen and (max-width: 1100px) {
        gap: 4px;
      }
      .settting-btn {
        display: flex;
        justify-content: center;
        width: 45%;

        @media only screen and (max-width: 1100px) {
          width: 90%;
          margin-bottom: 8px;
        }
        &:hover {
          color: var(--adf-color-text-05-light);
          background-color: var(--adf-color-primary-light);
        }
        .circle-loader {
          width: 40px;
          transition: all 0.2s ease-in-out !important;
        }
      }
    }
  }
}
