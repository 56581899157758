.coming-soon {
  width: calc(100% - 280px);
  display: flex;
  justify-content: center;
  align-items: center;
  .label {
    color: var(--adf-color-success);
    font-size: var(--font-size-7);
    font-weight: 600;
  }
}
