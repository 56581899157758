//swagger-overrides start
.swagger-ui {
  color: var(--adf-color-text-06-light);
}

.swagger-ui section.models .model-container {
  background: var(--adf-color-bg-primary-light);
  &:hover {
    background: var(--adf-color-bg-primary-light);
  }
}

.swagger-ui .model-title {
  color: var(--adf-color-text-80-light);
}

.swagger-ui .model-toggle {
  color: var(--adf-color-text-80-light);
}

.swagger-ui .model-box .model {
  span {
    color: var(--adf-color-text-80-light);
  }
  tbody {
    color: var(--adf-color-text-80-light);
  }
}
.swagger-ui .model-toggle:after {
  color: green;
}
.swagger-ui .models-control {
  color: var(--adf-color-text-80-light);
  svg {
    fill: var(--adf-color-text-80-light);
  }
}
.swagger-ui .models-control button svg {
  color: var(--adf-color-text-80-light);
}

.swagger-ui div .wrapper {
  color: var(--adf-color-text-06-light);

  .opblock-tag-section .opblock-tag .renderedMarkdown {
    color: #8c97b8;
  }
  .opblock .opblock-summary-description {
    color: #8c97b8;
    flex: 1 1 auto;
    font-family: sans-serif;
    font-size: 13px;
    word-break: break-word;
  }
  .opblock .opblock-body {
    color: #8c97b8;
    flex: 1 1 auto;
    font-family: sans-serif;
    font-size: 13px;
    word-break: break-word;
    .opblock-description {
      p {
        color: #8c97b8;
      }
      code {
        background: var(--adf-color-bg-100-light);
        color: var(--adf-color-text-20-light);
      }
    }
    .opblock-description-wrapper {
      .examples-select {
        color: #8c97b8;
      }
      .model-example .tab .tabitem {
        color: #8c97b8;
      }
    }
    .loading-container {
      writing-mode: horizontal-tb;
      white-space: nowrap;
    }
    .responses-wrapper .responses-inner .responses-table {
      .col_header {
        color: var(--adf-color-text-06-light);
      }
      td {
        color: var(--adf-color-text-06-light);
        .tabitem {
          color: #8c97b8;
        }
      }
    }
    .opblock-section {
      .parameters-container .table-container .parameters {
        th {
          color: var(--adf-color-text-06-light);
        }
        td div {
          color: #8c97b8;
        }
      }
    }
  }
}

.swagger-ui .info {
  margin: 50px 0;
  display: none !important;
}

.swagger-ui a.nostyle,
.swagger-ui a.nostyle:visited {
  color: var(--adf-color-text-80-light);
}

.swagger-ui .scheme-container {
  display: none;
}

//swagger-overrides end
