:root {
  --adf-color-primary-light: #3c65d6;
  --adf-color-primary-dark: #4574f5;
  --adf-color-accent-light: #e5ae40;
  --adf-color-accent-dark: #f5c462;
  --adf-color-danger-dark: #f56262;
  --adf-color-bg-100-light: #ffffff;
  --adf-color-bg-90-light: #f0f4ff;
  --adf-color-bg-80-light: #e6e6e6;
  --adf-color-bg-100-dark: #0d0e0f;
  --adf-color-bg-90-dark: #1b1c1f;
  --adf-color-bg-80-dark: #282a2e;
  --adf-color-bg-primary-light: #fff;

  --adf-color-text-light: #17191f;
  --adf-color-text-80-light: #464c5c;
  --adf-color-text-50-light: #8c97b8;
  --adf-color-text-60-light: #747e99;
  --adf-color-text-100-light: #17191f;
  --adf-color-text-90-light: #2f323d;
  --adf-color-text-70-light: #5d657a;
  --adf-color-text-40-light: #a3b1d6;
  --adf-color-text-30-light: #2f323d;
  --adf-color-text-20-light: #3c65d6;

  --adf-color-input-border-light: #d7dff5;
  --adf-color-input-border-focus-light: #3c65d6;
  --adf-color-input-border-dark: #36383d;
  --adf-color-input-border-focus-dark: #a2a7b8;
  --adf-color-input-background: #f5f8ff;
  --adf-color-delete: #ef4388;
  --adf-color-orange: #efb643;
  --adf-color-table-header: #f5f8ff;
  --adf-color-table-header-text: #1a4f9e;
  --adf-color-border: #3c65d6;

  --adf-color-label-dark: #6c707a;

  --adf-color-header-label-dark: #1f1f1f;

  --adf-color-white-border: #ebeef5;
  --adf-color-success: #4aaf54;
  --adf-color-green: #33b87a;

  --adf-color-error: #f56262;
  --adf-color-black: #000000;
  --adf-color-bg-modal: #ebeef5;
  --adf-color-cancel-btn: #51545c;
  --adf-color-bg-cancel-btn: hsla(224, 6%, 23%, 0.06);
  --adf-color-transparent: transparent;
  --adf-color-text-modal: #3d3d3d;
  --adf-color-bg-copy: #7a7a7a;
  --adf-color-input-border-light-1: #ced2de;
  --adf-color-water-border: #c8e1f8;
  --adf-color-outer-space-border: #3f4b5b;
  --adf-color-waldon-blue-border: #819abb;
  --adf-color-dodger-blue: #1e90ff;
  --adf-color-gray-dark: #111111;
  --adf-color-gray-very-dark: #222222;
  --adf-color-charcoal-dark: #333333;
  --adf-color-gray-medium-dark: #444444;
  --adf-color-gray-medium-light: #999999;
  --adf-color-dark-pastel-blue: #81aace;
  --adf-color-beau-blue: #b9d2e8;
  --adf-color-beau-blue: #b9d2e8;
  --adf-color-tart-orange: #f54545;
  --adf-color-lavender-gray: #bcc3d6;
  --adf-color-darkgunmetal: #212529;
  --adf-color-davy-grey: #565656;
  --adf-color-cultured: #f7f7f7;

  // border-radius
  --border-radius: 16px;

  // margin
  --margin: 1rem;

  //padding

  --padding: 16px;
  // font-size
  --font-size-1: 10px;
  --font-size-2: 12px;
  --font-size-3: 14px;
  --font-size-4: 16px;
  --font-size-5: 20px;
  --font-size-6: 24px;
  --font-size-7: 32px;

  // line-height

  --line-height-1: 0.625rem; // for 10px font size
  --line-height-2: 1rem; // for 12px font size
  --line-height-3: 1.25rem; // for 14px font size
  --line-height-4: 1.5rem; // for 16px font size
  --line-height-5: 2rem; // for 20px font size
  --line-height-6: 2.5rem; // for 24px font size

  --title: #2f323d;
  --node-bg-color: #fff;
  --node-color: #000;
  --border-primary: #d7dff5;
  --bg-primary: #ffffff;
  --icon-color: #3c65d6;
  --theme-toggle-color: "#000";
  --node-stroke: #3c65d6;
  --text-primary-tab: #8c97b8;
  --text-primary-active-tab: #3c65d6;

  --border-100-light: #3c65d6;
  --adf-color-text-10-light: #464c5c;
  --border-dashed: #cad1e6;
  --adf-color-text-05-light: #fff;
  --scroll-bar: #3c65d6;
  --btn-primary-light: #4e36d1;
  --btn-text-primary-light: #000;
  --adf-color-text-06-light: #000;
  --adf-color-nav-bg-90-dark: #fff;
  --adf-color-nav-text-light: #171618;
  --adf-disable-bg: #ffffff;
  --adf-disable-border: #cccccc;
  --adf-disable-text-color: #545353;
}

.dark-mode {
  --adf-color-bg-90-dark: #fff;
  --btn-primary-light: #4e36d1;
  --btn-text-primary-light: #ffffff;
  --adf-color-bg-100-light: #100c27;
  --adf-color-text-90-light: #d9d9d9;
  --bg-color: #0c1427;
  --bg-primary: #1b1d3c;
  --title: #f5af45;
  --node-bg-color: #1b1d3c;
  --node-color: #d6cdfe;
  --border-primary: #504190;
  --icon-color: #02d18b;
  --adf-color-input-background: rgba(60, 101, 214, 0.06);
  --adf-color-primary-light: #1b1d3c;
  --theme-toggle-color: #fff;
  --adf-color-label-dark: #9b93be;
  --adf-color-text-80-light: #ffffff;
  --adf-color-text-60-light: #d6cdfe;
  --node-storke: #fff;
  --input-color: #fff;
  --adf-color-input-border-light: #504190;
  --node-stroke: #04c2c9;
  --text-primary-tab: #8c97b8;
  --text-primary-active-tab: #00ffff;
  --adf-color-text-30-light: #909dc2;
  --adf-color-text-20-light: #04c2c9;
  --adf-color-text-10-light: #d6cdfe;
  --adf-color-text-100-light: #d7dbe9;
  --border-100-light: #04c2c9;
  --border-dashed: #cad1e6;
  --adf-color-text-05-light: #fff;
  --scroll-bar: #fff;
  --adf-color-border: #fff;
  --adf-color-text-light: #171618;
  --adf-color-bg-100-dark: #a9017c;
  --adf-color-text-06-light: #fff;
  --adf-color-bg-primary-light: #1b1d3c;
  --adf-color-nav-bg-90-dark: #1b1c1f;
  --adf-color-nav-text-light: #fff;
  --adf-disable-bg: #222222;
  --adf-disable-border: #444444;
  --adf-disable-text-color: #d2d0d0;
}
