.signin-wrapper {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  inset: 0;
  &__inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 480px;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    &__form {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      padding: 48px 32px;
      width: 100%;
      text-align: start;
      .input__field {
        color: #000 !important;
      }
      .input__text-field {
        border: 1px solid #d7dff5;
      }

      &__title {
        color: #17191f;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
      &__subtitle {
        color: #747e99;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
