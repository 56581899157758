.adf-text-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-margin) * 0.25);
  font-weight: 600;
  &__adf-input-label-text {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    color: var(--adf-color-text-70-light);
    // text-transform: capitalize;
  }

  &__adf-input-field {
    width: 100%;
    resize: none;
    display: flex;
    align-items: center;
    background: var(--adf-color-input-background);
    border: 1px solid var(--adf-color-input-border-light);
    border-radius: calc(var(--base-border-radius) * 0.5);
    padding: var(--base-padding);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    height: 100% !important;
    outline: none;
    &:focus {
      border: 1px solid var(--adf-color-primary-light);
      transition: 0.2s ease-in all;
    }
  }
  &__adf-input-field::placeholder {
    color: #ccc;
  }
}
.adf-text-area-field {
  resize: none;
}

.adf-input__label-text {
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  color: var(--adf-color-text-70-light);
}
