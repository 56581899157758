.resource-config {
  height: 100%;
  &__inner {
    display: flex;
    flex-direction: column;
    .form-wrapper {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 50%;
    }

    &__scroller {
      display: flex;
      justify-content: center;
      min-width: 100%;
      border-bottom: 1px solid var(--adf-color-input-border-light);
      &__left,
      &__right {
        display: flex;
        align-items: center;
        color: var(--text-primary-tab);
        cursor: pointer;
        max-width: 10%;
      }
      &__tab-header {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 52px;
        width: 95%;
        padding: 0 5px;

        &__item {
          flex: 1 0 auto;
          white-space: nowrap;
          align-items: center;
          color: var(--text-primary-tab);
          cursor: pointer;
          font-size: var(--font-size-3);
          font-style: normal;
          font-weight: 500;
          line-height: var(--line-height-3);
          padding: 16px 0px 16px 0px;
          position: relative;
          text-align: center;
        }

        .disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .active {
          color: var(--text-primary-active-tab);
          // width: inherit;

          &:after {
            background: var(--text-primary-active-tab);
            bottom: -1px;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
          }
        }
      }
    }
    &__tab-body {
      display: flex;
      flex-direction: column;
      padding-top: 18px;
      gap: 24px;
      height: calc(100vh - 250px);

      padding-right: 16px;
      overflow-y: scroll;

      @media only screen and (max-width: 1100px) {
        height: calc(100vh - 400px);
      }

      &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--adf-color-text-90-light);
        margin-bottom: -16px;
      }
    }

    &__toggle-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__label {
        font-size: 14px;
        line-height: 20px;
        color: var(--adf-color-text-90-light);
      }
    }

    &__note {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #8c97b8;
    }
  }

  .select-operations {
    .options_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .option {
      width: 20% !important;
      min-width: unset !important;
      cursor: pointer;
    }
  }

  .choose-type {
    .options_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .option {
      width: 85px !important;
      height: 80px;
      min-width: unset !important;
      cursor: pointer;
      padding: 0px 8px;
    }

    .option-bottom {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #8c97b8;
    }

    .option-top {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #6c707a;
    }
  }

  .options-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    flex-wrap: wrap;
  }

  .option {
    border: 1px solid var(--adf-color-input-border-light);
    color: var(--adf-color-label-dark) !important;
    font-size: 12px;
    text-align: center;
    // display: grid;
    display: flex;
    // place-items: center;
    justify-content: center;
    min-height: 50px;
    color: #fff;
    border-radius: 8px;
    padding: 8;
    width: 100%;
    min-width: 50%;

    &:hover {
      border: 1px solid var(--border-100-light);
      border-radius: 6px;
      transition: 0.4s ease-in-out all;
      background: var(--adf-color-input-background);
    }
  }

  .select-resource-wrapper {
    .options-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap !important;
      gap: 0px !important;

      &:first-child {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }
    }

    .option {
      border-radius: 0px !important;
      cursor: pointer;

      &:first-child {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }

      &:last-child {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
    }
  }

  .binary-choice__label {
    color: var(--adf-color-label-dark);
    font-size: 12px;
  }

  .description__label {
    color: var(--adf-color-label-dark);
    font-size: 12px;
  }

  .note__label {
    color: var(--adf-color-label-dark);
    font-size: 14px;
    letter-spacing: 1px;
    margin: auto;
  }

  .choose-visibility {
    .options_wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: center !important;
    }

    .option {
      max-width: 100px !important;
      min-width: unset !important;
      cursor: pointer;
      height: 80px;
    }

    .option-bottom {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      text-align: center;

      color: #8c97b8;
    }

    .option-top {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      text-align: center;

      color: #6c707a;
    }
  }
}

.active-binary-option {
  background: var(--adf-color-input-background);
  border: 1px solid var(--border-100-light) !important;
}

.border-line {
  border-bottom: 1px solid #504190;
  margin-top: 16px;
}

.upload-manual-csv-button {
  width: 77px;
  height: 36px;
  background: "var(--adf-color-input-background)";
  border: 1px solid #d7dff5;
  border-radius: 6px;
  position: relative;
  display: block;
  margin: auto;
  top: 9px;
  cursor: pointer;
}

.btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    &:hover {
      color: var(--adf-color-text-05-light);
      background-color: var(--adf-color-primary-light);
    }
  }
}

.relationship-note {
  width: 100%;
  color: var(--adf-color-text-10-light);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  overflow-wrap: break-word; /* Updated property */
}

.relationship-note span {
  color: var(--icon-color);
  font-weight: 600;
  margin: 0 8px;
  white-space: normal; /* Updated property */
  word-break: break-all;
}

.relationship-item-wrapper {
  background-color: var(--adf-color-input-background);
  color: var(--text-primary-tab);
  height: 56px;
  display: "flex";
  flex-wrap: "wrap";
  justify-content: "space-around";
  align-items: "center";
  padding: "0 30px";
  width: 100%;

  &:hover {
    .deleteRelationshipIcon-wrapper {
      display: block;
      color: #fff;
      background-color: green;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      position: relative;
      top: 16px;
      right: 16px;
      font-size: 16px;
    }
  }
}

// .deleteRelationshipIcon-wrapper {
//   cursor: pointer;
//   display: none;
//   display: block;
//   color: #fff;
//   background-color: red;
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
//   display: grid;
//   place-items: center;
//   position: relative;
//   top: 16px;
//   right: 16px;
//   font-size: 16px;
// }
