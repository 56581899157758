.api-editor {
  height: 100%;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  &__inner {
    display: flex;
    gap: 16px;
    width: 100%;
    height: 100%;
    &__editor {
      width: 75%;
      border-right: 1px solid #d7dff5;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      &__node-tree-wrapper {
        flex: 12;
        width: 100%;
      }
      &__action-wrapper {
        // flex: 1;
        background: var(--bg-primary);
        border: 1px solid var(--border-primary);
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px;
        max-width: 200px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        &__icon-wrapper {
          display: grid;
          place-items: center;
          width: 40px;
          height: 40px;
          cursor: pointer;
          i {
            color: var(--adf-color-text-30-light);
          }
        }
        .active {
          background: rgba(60, 101, 214, 0.06);
          border: 1px solid var(--border-primary);
          border-radius: 6px;
          i {
            color: var(--adf-color-text-20-light);
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }
    &__setting {
      width: 30%;
      height: 100%;
      @media only screen and (max-width: 900px) {
        height: calc(100% - 120px);
      }
    }
  }
}

.goback-innner-wrapper {
  display: flex;
  align-items: center;
  padding: 8px;
  min-width: 120px;
  justify-content: center;
  max-height: 48px;
  color: var(--adf-color-text-20-light);
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  top: 14px;
  left: 16px;
  z-index: 1;
  i {
    font-weight: 900;
    font-size: 24px;
    margin-left: -8px;
  }
}
