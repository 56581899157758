.sidebar {
  border-right: 1px solid #d7dff5;
  min-width: 200px;
  height: 100vh;
  position: relative;
  transition: width 0.5s ease-in-out; /* Changed the transition duration */

  &--collapsed {
    width: 70px;
    min-width: 70px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 80px;
    &__logo {
      width: 30px;
      height: 30px;
    }
  }

  &__list {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: rgb(255, 255, 255);
    margin-top: 24px;
    align-items: center;

    &__item {
      color: var(--adf-color-text-80-light);
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: var(--font-size-3);
      font-weight: 600;
      white-space: nowrap;
      cursor: pointer;
      position: relative;
      width: 80%;

      i {
        font-size: var(--font-size-5);
      }
    }

    &__item--collapsed {
      display: flex;
      justify-content: center;
      // padding-left: 0;
    }
  }

  &__collapse-button {
    position: absolute;
    bottom: 24px;
    right: -15px;
    top: 64px;
    width: 30px;
    height: 30px;
    background-color: var(--bg-primary);
    color: var(--adf-color-text-80-light);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;
    border-radius: 50%;
    border: 1px solid #d7dff5;
    // transition: bottom 0.3s ease;
  }

  &--collapsed &__collapse-button {
    right: -15px;
  }

  &__list__item--collapsed .ri-collapse-icon {
    margin-right: 0;
  }
}
