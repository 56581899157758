.describe-api {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 500px;
    &__img-wrapper {
      height: 134px;
      max-width: 250px;
      img {
        max-width: 250px;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      &__label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #464c5c;
      }
      &__sub-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #747e99;
        text-align: left;
      }
    }
  }
  &__action-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    &__item {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 24px;
      padding: 16px;
      background: var(--adf-color-input-background);
      border: 2px solid #d7dff5;
      border-radius: 8px;
      height: 60px;
      cursor: pointer;
      &__leftIcon {
        background: rgba(69, 108, 245, 0.12);
        border-radius: 24px;
        width: 30px;
        height: 30px;
        display: grid;
        place-items: center;
        i {
          color: #0c87fd;
        }
      }

      &__loader {
        flex: 1;
      }

      &__label {
        flex: 1;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #747e99;
      }
      &__rightIcon {
        font-size: 24px;
        line-height: 24px;
        color: #747e99;
        margin-top: 4px;
      }
    }
  }
}
