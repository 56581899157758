.swagger-collection {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--title);
    }
    &__btn {
      width: 133px;
      height: 48px;
      font-weight: 500;
      display: unset;
      background: rgba(60, 101, 214, 0.06);
      border-radius: 8px;
      color: var(--adf-color-text-70-light);
    }
  }
  &__inner {
    background: var(--adf-color-bg-100-light);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    border: 1px solid var(--adf-color-input-border-light);
    overflow: unset;
  }
  rapi-doc::part(section-navbar) { /* <<< targets navigation bar */
    background-color: var(--bg-primary);
    color: var(--adf-color-text-90-light);
  }
  // rapi-doc::part(section-main-content) { /* <<< targets main content */
  //   background-color: var(--bg-primary);
  //   color: var(--adf-color-text-90-light);
  // }
  rapi-doc::part(textbox-nav-filter) { /* <<< targets nav search bar */
    background: var(--bg-primary);
    color: var(--adf-color-text-90-light);
  }
  rapi-doc::part(section-navbar-item) { /* <<< targets nav items */
    color: var(--adf-color-text-90-light);
  }
  // rapi-doc::part(section-header) { /* <<< targets section bar */
  //   background: var(--bg-primary);
  //   color: #676a89;
  // }
  rapi-doc::part(btn-search) { /* <<< targets search button */
    background-color: var(--primary-color);
    color: var(--primary-color-invert);
  }
}
