.template-screen {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    &__img-wrapper {
      max-width: 250px;
      height: 188px;
    }
    .button__large {
      height: 48px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      &__label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--adf-color-text-80-light);
      }
      &__sub-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: var(--adf-color-text-60-light);
      }
    }
  }
}
