.go-back {
  display: flex;
  gap: 0;
  align-items: center;
  color: var(--adf-color-text-20-light);
  font-size: 16px;
  font-weight: 500;
  // padding: 10px 0;
  cursor: pointer;
}

.visible {
  display: block;
}

.invisible {
  display: none;
}

.next-wrapper {
  color: var(--text-primary-tab);
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  margin-right: 1px;
}

.summary-list {
  border-top: 2px solid var(--text-primary-tab);
  height: 100%;
}

.summary-item {
  // border-bottom: 2px solid var(--text-primary-tab);
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  .linkedTo {
    color: #fff;
    font-size: 18px;
  }
  .expression {
    font-size: 16px;
    padding: 10px;
    border-radius: 6px;
  }

  .delete-wrapper {
    position: relative;
    padding: 10px;
  }
}
.delete-summary-mapping {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #a9017c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  z-index: 100;
  // visibility: hidden;
  cursor: pointer;
  float: right;
  position: absolute;
  right: 10px;
  bottom: 30px;
}

.add-new-summary {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  gap: 8px;
  cursor: pointer;
  color: var(--adf-color-text-20-light);
}
