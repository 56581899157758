.skelton-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__body {
    overflow-y: auto;
    flex-grow: 1;

  }

  &__powered-by{
    width: 56%;
    margin: auto;
    margin-bottom: -4px;
  }
  
}
