.upload-screen {
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 668px;
    flex: 1;
    justify-content: center;
    align-self: center;

    img {
      max-width: 250px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      &__label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #464c5c;
      }
      &__sub-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #747e99;
        text-align: left;
      }
    }
  }
  &__action-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .upload-button-wrapper {
    width: 100%;
    background: transparent;
    color: rgb(255, 255, 255);
    padding: 24px;
    border-top: 1px solid rgb(215, 223, 245);
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    .button {
      min-width: 120px;
      height: 48px;
      display: grid;
      place-items: center;
    }
    .Upload-btn{
     min-width: 176px;
      min-height: 56px;
      justify-content: center;
    }
  }
}

.upload-file-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  &__notice {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #5d657a;
    span {
      color: #3c65d6;
    }
  }
}

.fetch-button {
  width: 77px;
  height: 36px;
  border: 1px solid #d7dff5;
  border-radius: 6px;
  position: absolute;
  right: 16px;
  top: 34px;
  cursor: pointer;
  background: var(--adf-color-white-border);
  color: var(--adf-color-bg-80-dark);
}

.action-header-tab {
  align-items: center;
  border-bottom: 1px solid var(--adf-color-input-border-light);
  display: flex;
  justify-content: space-around;
  height: 52px;
  .active {
    color: var(--adf-color-primary-dark);
    width: inherit;
    &:after {
      background: var(--adf-color-primary-dark);
      bottom: -1px;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }
}
.tab {
  align-items: center;
  color: var(--text-primary-tab);
  cursor: pointer;
  font-size: var(--font-size-3);
  font-style: normal;
  font-weight: 500;
  line-height: var(--line-height-3);
  padding: var(--padding);
  position: relative;
  text-align: center;
}

.fetch-url-wrapper {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  .input__field {
    width: calc(100% - 100px);
  }
}

.file-upload-container {
  .file-upload {
    min-height: 199px;
  }
}
.Next-button{
  background-color: var(--adf-color-primary-dark);
  justify-content: center;
  height: 56px;
  width: 176px;
}