.api-setting {
  &__inner {
    background: var(--adf-color-bg-100-light);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    padding: 24px 16px;
    border: 1px solid var(--adf-color-input-border-light);
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--title);
      }
      &__edit-icon {
        color: #6c7489;
        font-size: 20px;
        cursor: pointer;
      }
    }
    &__inputs {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &__btn-wrapper {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 16px;
      .button {
        width: 120px;
        height: 52px;
        display: unset;
      }
    }
  }
}
