.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.confirmation-modal-content {
  background-color: var(--adf-color-white-border);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  position: relative;
}

.confirmation-modal h3 {
  margin-bottom: 10px;
  color: var(--adf-color-text-light);
}

.confirmation-modal p {
  margin-bottom: 10px;
  color: var(--adf-color-bg-100-dark);
  word-wrap: none;
}

.confirmation-modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.confirmation-modal button + button {
  margin-left: 10px;
}
.close-modal {
  position: absolute;
  color: var(--adf-color-text-light);
  top: 20px;
  right: 20px;
  font-size: 19px;
  cursor: pointer;
}
.modal-btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  .action-btn {
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    &:hover {
      color: var(--adf-color-text-05-light);
      background-color: var(--adf-color-primary-light);
    }
  }
}
