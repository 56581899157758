.card-wrapper {
  height: 100%;
  position: relative;

  color: var(--adf-color-text-20-light);
  width: 100%;
}

.accordian-body {
  border-left: 2px solid var(--adf-color-input-border-light);
  border-right: 2px solid var(--adf-color-input-border-light);
  border-bottom: 2px solid var(--adf-color-input-border-light);
  border-radius: 5px;
}

.non-accordian-body {
  border: 2px solid var(--adf-color-input-border-light);
  border-radius: 5px;
  width: 100%;
  padding-bottom: 30px;
}

.query-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .accordian-title {
    flex: 1;
    padding-right: 60px;
  }

  .query-select-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    // z-index: 999;
    right: 10px;
  }
}

.accoirdian-summary-attribute {
  font-size: 16px;
  padding: 5px 10px;
  font-weight: 600;
  width: 100%;
  color: var(--btn-text-primary-light);
  word-break: break-all;
}

.select-wrapper {
  display: flex;
  gap: 8px;
  width: 100%;
}
.select-attribute {
  flex: 5;
}
.select-operator {
  flex: 2;
}

.expression {
  color: var(--adf-color-bg-80-dark);
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
  height: fit-content;

  .expression-tags-wrapper {
    display: flex;
    align-items: center;
  }

  .plus-icon-wrapper {
    font-size: 20px;
    // display: flex;
    // align-items: center;

    .ri-add-circle-fill {
      color: #a9017c;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  .delete-icon-visibility {
    position: relative;

    &:hover .delete-select {
      visibility: visible;
    }
  }

  .operator-select {
    min-width: 30px;
    border-radius: 8px;
    color: #000;
  }

  .attribute-select {
    min-width: 200px;
  }

  .delete-select {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #a9017c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #fff;
    font-weight: 500;
    z-index: 100;
    transform: translateY(-10px);
    visibility: hidden;
    cursor: pointer;
  }
}

.save {
  color: var(--node-color);
  background: var(--node-bg-color);
  padding: 10px;
  margin: 10px;
  width: max-content;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    &:hover {
      color: var(--adf-color-text-05-light);
      background-color: var(--adf-color-primary-light);
    }
  }
}

.summaryCard-description {
  padding-left: 10px;
  color: var(--btn-text-primary-light);
  font-size: 12px;
  padding-bottom: 10px;
}

.card-header-select {
  width: fit-content;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.accordian-title {
  background-color: var(--node-bg-color);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--btn-text-primary-light);
  border-radius: 2px;
  border: 2px solid var(--adf-color-input-border-light);
}
